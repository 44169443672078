import React, { useEffect, useRef, useState } from 'react';

import gsap from "gsap";



export default function TalkAbout() {

    const monPathRef = useRef([null])
    const monPath2 = useRef([null])
    const monSvgRef = useRef([null])

    useEffect(() => {
        gsap.to(monSvgRef.current,
            {
                rotateZ: -5,
                duration: 1,
                yoyo: true,
                repeat: -1,
                repeatDelay: 0.5,
            });
    }, []);
    useEffect(() => {
        gsap.fromTo(monPath2.current,
            {

                rotateZ: 0,
            },
            {
                rotateZ: 1,
                duration: 1,
                yoyo: true,
                repeat: -1,
                repeatDelay: 0.5,
            });
    }, []);
    useEffect(() => {
        gsap.fromTo(monPathRef.current,
            {
                x: -2,
                y: 2,
            },
            {
                x: 2,
                y: -2,
                rotateZ: -2,
                duration: 1,
                yoyo: true,
                repeat: -1,
                repeatDelay: 0.5,
            });
    }, []);

    return (
        <section className='talkAbout'>
            <h1>Ils <br /> parlent <br /> de nous</h1>

            <div className="hand">
                <ul>
                    <li>
                        <svg className='animeHand' ref={(el) => (monSvgRef.current[0] = el)} width="312" height="245" viewBox="0 0 312 245" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path ref={(el) => (monPathRef.current[0] = el)} d="M104.5 148C104.5 148 104.486 137.815 100.5 133.5C95.8398 128.456 91 126 83.5 129C76 132 57.7807 147.444 57.5 153.5C57.2193 159.556 57.3465 164.084 61.5 168.5C66.0758 173.365 78 173 78 173M104.5 148L78 173M104.5 148C104.5 148 119.829 145.187 126 151C131.215 155.912 131.5 168.5 131.5 168.5M78 173C78 173 77.976 186.601 83.5 192C88.4899 196.877 100.5 197.5 100.5 197.5M100.5 197.5L131.5 168.5M100.5 197.5C100.5 197.5 100.482 211.997 106.5 217.5C112.268 222.774 126 222 126 222M131.5 168.5C131.5 168.5 145.385 169.055 150.5 175C155.671 181.011 154 195 154 195M154 195L126 222M154 195C154 195 160.5 192.5 169.5 201.5C178.5 210.5 174.5 215.5 174.5 215.5C174.5 215.5 157.845 239.065 150.5 240.5C143.155 241.935 137.108 242.956 131.5 238C126.549 233.625 126 222 126 222" stroke="black" strokeWidth="4" />
                            <path d="M62.5 144.5L35.5 103M35.5 103L3.5 76.5L62.5 3L95 26M35.5 103L95 26M95 26L126 33.5M191 123L242.5 172.5M242.5 172.5C242.5 172.5 253 177 260.5 167C268 157 260.5 147.5 260.5 147.5M242.5 172.5C242.5 172.5 248 182.5 239.5 191.5C231 200.5 219 196.5 219 196.5M219 196.5L181.5 159.5M219 196.5C219 196.5 222.5 207.5 215 215C207.5 222.5 195.5 220 195.5 220M195.5 220L175 200.5M195.5 220C195.5 220 195.5 232 188.5 238.5C181.5 245 172 242.5 172 242.5L160.5 233" stroke="black" strokeWidth="4" />
                            <path ref={(el) => (monPath2.current[0] = el)} d="M280.5 97L259.5 147L180 77C166.355 71.9107 151.265 67.0958 145 72.5C138.735 77.9042 128 110 128 110C128 110 113.187 119.774 105 115.5C97.8006 111.741 95.5 97 95.5 97L126 34L173 25.5H226.5M280.5 97L309 72.5L259.5 3L226.5 25.5M280.5 97L226.5 25.5" stroke="black" strokeWidth="4" />
                        </svg>
                    </li>
                    <li>
                        <svg className='animeHand' ref={(el) => (monSvgRef.current[1] = el)} width="312" height="245" viewBox="0 0 312 245" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path ref={(el) => (monPathRef.current[1] = el)} d="M104.5 148C104.5 148 104.486 137.815 100.5 133.5C95.8398 128.456 91 126 83.5 129C76 132 57.7807 147.444 57.5 153.5C57.2193 159.556 57.3465 164.084 61.5 168.5C66.0758 173.365 78 173 78 173M104.5 148L78 173M104.5 148C104.5 148 119.829 145.187 126 151C131.215 155.912 131.5 168.5 131.5 168.5M78 173C78 173 77.976 186.601 83.5 192C88.4899 196.877 100.5 197.5 100.5 197.5M100.5 197.5L131.5 168.5M100.5 197.5C100.5 197.5 100.482 211.997 106.5 217.5C112.268 222.774 126 222 126 222M131.5 168.5C131.5 168.5 145.385 169.055 150.5 175C155.671 181.011 154 195 154 195M154 195L126 222M154 195C154 195 160.5 192.5 169.5 201.5C178.5 210.5 174.5 215.5 174.5 215.5C174.5 215.5 157.845 239.065 150.5 240.5C143.155 241.935 137.108 242.956 131.5 238C126.549 233.625 126 222 126 222" stroke="black" strokeWidth="4" />
                            <path d="M62.5 144.5L35.5 103M35.5 103L3.5 76.5L62.5 3L95 26M35.5 103L95 26M95 26L126 33.5M191 123L242.5 172.5M242.5 172.5C242.5 172.5 253 177 260.5 167C268 157 260.5 147.5 260.5 147.5M242.5 172.5C242.5 172.5 248 182.5 239.5 191.5C231 200.5 219 196.5 219 196.5M219 196.5L181.5 159.5M219 196.5C219 196.5 222.5 207.5 215 215C207.5 222.5 195.5 220 195.5 220M195.5 220L175 200.5M195.5 220C195.5 220 195.5 232 188.5 238.5C181.5 245 172 242.5 172 242.5L160.5 233" stroke="black" strokeWidth="4" />
                            <path ref={(el) => (monPath2.current[1] = el)} d="M280.5 97L259.5 147L180 77C166.355 71.9107 151.265 67.0958 145 72.5C138.735 77.9042 128 110 128 110C128 110 113.187 119.774 105 115.5C97.8006 111.741 95.5 97 95.5 97L126 34L173 25.5H226.5M280.5 97L309 72.5L259.5 3L226.5 25.5M280.5 97L226.5 25.5" stroke="black" strokeWidth="4" />
                        </svg>
                    </li>
                    <li>
                        <svg className='animeHand' ref={(el) => (monSvgRef.current[2] = el)} width="312" height="245" viewBox="0 0 312 245" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path ref={(el) => (monPathRef.current[2] = el)} d="M104.5 148C104.5 148 104.486 137.815 100.5 133.5C95.8398 128.456 91 126 83.5 129C76 132 57.7807 147.444 57.5 153.5C57.2193 159.556 57.3465 164.084 61.5 168.5C66.0758 173.365 78 173 78 173M104.5 148L78 173M104.5 148C104.5 148 119.829 145.187 126 151C131.215 155.912 131.5 168.5 131.5 168.5M78 173C78 173 77.976 186.601 83.5 192C88.4899 196.877 100.5 197.5 100.5 197.5M100.5 197.5L131.5 168.5M100.5 197.5C100.5 197.5 100.482 211.997 106.5 217.5C112.268 222.774 126 222 126 222M131.5 168.5C131.5 168.5 145.385 169.055 150.5 175C155.671 181.011 154 195 154 195M154 195L126 222M154 195C154 195 160.5 192.5 169.5 201.5C178.5 210.5 174.5 215.5 174.5 215.5C174.5 215.5 157.845 239.065 150.5 240.5C143.155 241.935 137.108 242.956 131.5 238C126.549 233.625 126 222 126 222" stroke="black" strokeWidth="4" />
                            <path d="M62.5 144.5L35.5 103M35.5 103L3.5 76.5L62.5 3L95 26M35.5 103L95 26M95 26L126 33.5M191 123L242.5 172.5M242.5 172.5C242.5 172.5 253 177 260.5 167C268 157 260.5 147.5 260.5 147.5M242.5 172.5C242.5 172.5 248 182.5 239.5 191.5C231 200.5 219 196.5 219 196.5M219 196.5L181.5 159.5M219 196.5C219 196.5 222.5 207.5 215 215C207.5 222.5 195.5 220 195.5 220M195.5 220L175 200.5M195.5 220C195.5 220 195.5 232 188.5 238.5C181.5 245 172 242.5 172 242.5L160.5 233" stroke="black" strokeWidth="4" />
                            <path ref={(el) => (monPath2.current[2] = el)} d="M280.5 97L259.5 147L180 77C166.355 71.9107 151.265 67.0958 145 72.5C138.735 77.9042 128 110 128 110C128 110 113.187 119.774 105 115.5C97.8006 111.741 95.5 97 95.5 97L126 34L173 25.5H226.5M280.5 97L309 72.5L259.5 3L226.5 25.5M280.5 97L226.5 25.5" stroke="black" strokeWidth="4" />
                        </svg>
                    </li>
                    <li>
                        <svg className='animeHand' ref={(el) => (monSvgRef.current[3] = el)} width="312" height="245" viewBox="0 0 312 245" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path ref={(el) => (monPathRef.current[3] = el)} d="M104.5 148C104.5 148 104.486 137.815 100.5 133.5C95.8398 128.456 91 126 83.5 129C76 132 57.7807 147.444 57.5 153.5C57.2193 159.556 57.3465 164.084 61.5 168.5C66.0758 173.365 78 173 78 173M104.5 148L78 173M104.5 148C104.5 148 119.829 145.187 126 151C131.215 155.912 131.5 168.5 131.5 168.5M78 173C78 173 77.976 186.601 83.5 192C88.4899 196.877 100.5 197.5 100.5 197.5M100.5 197.5L131.5 168.5M100.5 197.5C100.5 197.5 100.482 211.997 106.5 217.5C112.268 222.774 126 222 126 222M131.5 168.5C131.5 168.5 145.385 169.055 150.5 175C155.671 181.011 154 195 154 195M154 195L126 222M154 195C154 195 160.5 192.5 169.5 201.5C178.5 210.5 174.5 215.5 174.5 215.5C174.5 215.5 157.845 239.065 150.5 240.5C143.155 241.935 137.108 242.956 131.5 238C126.549 233.625 126 222 126 222" stroke="black" strokeWidth="4" />
                            <path d="M62.5 144.5L35.5 103M35.5 103L3.5 76.5L62.5 3L95 26M35.5 103L95 26M95 26L126 33.5M191 123L242.5 172.5M242.5 172.5C242.5 172.5 253 177 260.5 167C268 157 260.5 147.5 260.5 147.5M242.5 172.5C242.5 172.5 248 182.5 239.5 191.5C231 200.5 219 196.5 219 196.5M219 196.5L181.5 159.5M219 196.5C219 196.5 222.5 207.5 215 215C207.5 222.5 195.5 220 195.5 220M195.5 220L175 200.5M195.5 220C195.5 220 195.5 232 188.5 238.5C181.5 245 172 242.5 172 242.5L160.5 233" stroke="black" strokeWidth="4" />
                            <path ref={(el) => (monPath2.current[3] = el)} d="M280.5 97L259.5 147L180 77C166.355 71.9107 151.265 67.0958 145 72.5C138.735 77.9042 128 110 128 110C128 110 113.187 119.774 105 115.5C97.8006 111.741 95.5 97 95.5 97L126 34L173 25.5H226.5M280.5 97L309 72.5L259.5 3L226.5 25.5M280.5 97L226.5 25.5" stroke="black" strokeWidth="4" />
                        </svg>
                    </li>
                    <li>
                        <svg className='animeHand' ref={(el) => (monSvgRef.current[4] = el)} width="312" height="245" viewBox="0 0 312 245" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path ref={(el) => (monPathRef.current[4] = el)} d="M104.5 148C104.5 148 104.486 137.815 100.5 133.5C95.8398 128.456 91 126 83.5 129C76 132 57.7807 147.444 57.5 153.5C57.2193 159.556 57.3465 164.084 61.5 168.5C66.0758 173.365 78 173 78 173M104.5 148L78 173M104.5 148C104.5 148 119.829 145.187 126 151C131.215 155.912 131.5 168.5 131.5 168.5M78 173C78 173 77.976 186.601 83.5 192C88.4899 196.877 100.5 197.5 100.5 197.5M100.5 197.5L131.5 168.5M100.5 197.5C100.5 197.5 100.482 211.997 106.5 217.5C112.268 222.774 126 222 126 222M131.5 168.5C131.5 168.5 145.385 169.055 150.5 175C155.671 181.011 154 195 154 195M154 195L126 222M154 195C154 195 160.5 192.5 169.5 201.5C178.5 210.5 174.5 215.5 174.5 215.5C174.5 215.5 157.845 239.065 150.5 240.5C143.155 241.935 137.108 242.956 131.5 238C126.549 233.625 126 222 126 222" stroke="black" strokeWidth="4" />
                            <path d="M62.5 144.5L35.5 103M35.5 103L3.5 76.5L62.5 3L95 26M35.5 103L95 26M95 26L126 33.5M191 123L242.5 172.5M242.5 172.5C242.5 172.5 253 177 260.5 167C268 157 260.5 147.5 260.5 147.5M242.5 172.5C242.5 172.5 248 182.5 239.5 191.5C231 200.5 219 196.5 219 196.5M219 196.5L181.5 159.5M219 196.5C219 196.5 222.5 207.5 215 215C207.5 222.5 195.5 220 195.5 220M195.5 220L175 200.5M195.5 220C195.5 220 195.5 232 188.5 238.5C181.5 245 172 242.5 172 242.5L160.5 233" stroke="black" strokeWidth="4" />
                            <path ref={(el) => (monPath2.current[4] = el)} d="M280.5 97L259.5 147L180 77C166.355 71.9107 151.265 67.0958 145 72.5C138.735 77.9042 128 110 128 110C128 110 113.187 119.774 105 115.5C97.8006 111.741 95.5 97 95.5 97L126 34L173 25.5H226.5M280.5 97L309 72.5L259.5 3L226.5 25.5M280.5 97L226.5 25.5" stroke="black" strokeWidth="4" />
                        </svg>
                    </li>

                </ul>
            </div>

            <div id='ancreAbout' className="contactAbout">
                <span className='spanTxt'>ENTRER EN CONTACT - ENTRER EN CONTACT - ENTRER EN CONTACT - ENTRER EN CONTACT - ENTRER EN CONTACT&nbsp;</span>
                <span className='spanTxt'>ENTRER EN CONTACT - ENTRER EN CONTACT - ENTRER EN CONTACT - ENTRER EN CONTACT - ENTRER EN CONTACT&nbsp;</span>
            </div>
        </section>
    )
}
