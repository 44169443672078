import React from 'react'

import HowFaq from './components/HowFaq/HowFaq'
import MouseArrow from './components/Utils/MouseArrow'


export default function Faq() {
  return (
    <div>
      <HowFaq />
    </div>
  )
}
